import React, { useState, useRef, useEffect, useContext } from 'react'
import { StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';


function HpTopHeadImg() {
  const { t } = useTranslation();

  const imgList = [
    t('img.ollie'),
    t('img.howto'),
    t('img.search'),
    t('img.good'),
    t('img.compare'),
    t('img.question'),
    t('img.download'),
    t('img.analytics'),
    t('img.scroll'),
  ]
  const [img1PathNum, setImg1PathNum] = useState(0);


  useEffect(() => {
    setTimeout(() => {
      // 3秒ごとに画像を変える
      if(imgList.length == img1PathNum+1) {
        // 最後の場合
        setImg1PathNum(0);
      } else {
        // 次の画像
        setImg1PathNum(img1PathNum+1);
      }
    }, 3000)
  }, [img1PathNum])


  
  return (
    <div className='mainImgWrap'>
      <img
        className='mainImg1'
        src={imgList[img1PathNum]}
        alt='Skaters'
      />
      <img
        className='mainImg2'
        src={t('img.profile')}
        alt='Skaters'
      />
    </div>
  );
}


const styles = StyleSheet.create({
});

export default HpTopHeadImg;