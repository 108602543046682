import React, { useState, useRef, useContext } from 'react'
import { Dimensions, ScrollView, StyleSheet, Image, Text, View } from 'react-native';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { useTranslation } from 'react-i18next';

import { blackColor, fontColorWhite } from '../../../../../style/css/CmnStyle';
import { hpContext } from '../../../HPContext';
const windowHeight = Dimensions.get('window').height;

function HpContentFunction(props) {
  const { t } = useTranslation();
  const isWide = useContext(hpContext).isWide;
  const isTxtSmall = useContext(hpContext).isTxtSmall;

  const windowDimensions = useContext(hpContext).windowDimensions;
  const windowWidth = windowDimensions.width;

  const scrollViewRef = useRef(null);
  const [scrollX, setScrollX] = useState(0);

  const [isMaxLeft, setIsMaxLeft] = useState(true);
  const [isMaxRight, setIsMaxRight] = useState(false);


  const handleScroll = (event) => {
    // スクロール位置を取得
    const { contentOffset } = event.nativeEvent;
    const newScrollX = contentOffset.x;
    setScrollX(newScrollX);

    const contentOffsetX = event.nativeEvent.contentOffset.x;
    const contentSizeWidth = event.nativeEvent.contentSize.width;
    const layoutMeasurementWidth = event.nativeEvent.layoutMeasurement.width;

    // スクロールが最左端(画面半分サイズ)に到達したか判定
    setIsMaxLeft(newScrollX < windowWidth/2);

    // スクロールが最右端(画面半分サイズ)に到達したか判定
    if (contentOffsetX + layoutMeasurementWidth >= contentSizeWidth - windowWidth/2) {
      setIsMaxRight(true);
    } else {
      setIsMaxRight(false);
    }
  };


  const onPressLeft = () => {
    scrollViewRef.current.scrollTo({ x: scrollX-windowWidth , y: 0, animated: true });
  }

  const onPressRight = () => {
    scrollViewRef.current.scrollTo({ x: scrollX+windowWidth , y: 0, animated: true });
  }

  return (
    <View id='functions' style={[styles.container, {paddingBottom: isWide? 100 : 50}]}>

      {/* Skatersとは */}
      <Text style={isWide? styles.titleWide : styles.title}>{t('top.about.title')}</Text>
      <View
        style={[
          styles.underline,
          {marginHorizontal: isWide? windowWidth*0.4 : windowWidth*0.2},
          {marginBottom: isWide? 30 : 0},
        ]}
      ></View>
      
      <ScrollView
        ref={scrollViewRef}
        onScroll={handleScroll}
        horizontal // 横スクロールを有効にする
        style={styles.scrollContainer}
        contentContainerStyle={styles.contentContainer}
        scrollEventThrottle={16} // scrollEventThrottleを16に設定
        showsVerticalScrollIndicator={isWide ? true : false} 
        pagingEnabled={true}
      >

        <View style={[
          {width: isWide? windowWidth/3.5 : windowWidth},
          {paddingHorizontal: isWide? 50 : 25},
        ]}>
          <Image
            defaultSource={t('img.scroll_cover')}
            resizeMode={'contain'}
            style={[
              styles.functionImg,
              {height: isWide? windowHeight/2 : windowHeight/1.5},
            ]}
          />
          {/* スワイプに合わせて再生 */}
          <Text style={[
            styles.functionTitle,
            {fontSize: isTxtSmall? '1.1em' : '1.3em'},
          ]}>
            {t('top.about.swipe.title')}
          </Text>
          {/*
            スワイプに合わせて動画の再生位置を動かすことができるのでトリックの瞬間を見逃すことなく何度も見返せます。{"\n"}
            あなたのトリックや他スケーターのトリックを見てより良いスケートライフを楽しみましょう！
          */}
          <Text style={[
            styles.functionDescription,
            {fontSize: isWide? '1.3em' : '1em'},
          ]}>
            {t('top.about.swipe.discription')}
          </Text>
        </View>


        <View style={[
          {width: isWide? windowWidth/3.5 : windowWidth},
          {paddingHorizontal: isWide? 50 : 25},
        ]}>
          <Image
            defaultSource={t('img.howto')}
            resizeMode={'contain'}
            style={[
              styles.functionImg,
              {height: isWide? windowHeight/2 : windowHeight/1.5},
            ]}
          />
          {/* How to */}
          <Text style={[
            styles.functionTitle,
            {fontSize: isTxtSmall? '1.1em' : '1.3em'},
          ]}>
            {t('top.about.howto.title')}
          </Text>
          {/*
            タイミングごとの体の動かし方・意識することの詳細を動画に合わせて確認できます。
            初メイクから理想のスタイルまで、細かく何度も確認して憧れのトリックを手にいれましょう！
          */}
          <Text style={[
            styles.functionDescription,
            {fontSize: isWide? '1.3em' : '1em'},
          ]}>
            {t('top.about.howto.discription')}
          </Text>
        </View>


        <View style={[
          {width: isWide? windowWidth/3.5 : windowWidth},
          {paddingHorizontal: isWide? 50 : 25},
        ]}>
          <Image
            defaultSource={t('img.question')}
            resizeMode={'contain'}
            style={[
              styles.functionImg,
              {height: isWide? windowHeight/2 : windowHeight/1.5},
            ]}
          />
          {/* Q&A */}
          <Text style={[
            styles.functionTitle,
            {fontSize: isTxtSmall? '1.1em' : '1.3em'},
          ]}>
            {t('top.about.question.title')}
          </Text>
          {/*
            質問・相談することでスケーターとトリックごとに情報交換できます！
            またAIからのアドバイスももらえるので質問して理想のスタイルを手に入れましょう！
          */}
          <Text style={[
            styles.functionDescription,
            {fontSize: isWide? '1.3em' : '1em'},
          ]}>
            {t('top.about.question.discription')}
          </Text>
        </View>


        <View style={[
          {width: isWide? windowWidth/3.5 : windowWidth},
          {paddingHorizontal: isWide? 50 : 25},
        ]}>
          <Image
            defaultSource={t('img.compare')}
            resizeMode={'contain'}
            style={[
              styles.functionImg,
              {height: isWide? windowHeight/2 : windowHeight/1.5},
            ]}
          />
          {/* 並べて比較する */}
          <Text style={[
            styles.functionTitle,
            {fontSize: isTxtSmall? '1.1em' : '1.3em'},
          ]}>
            {t('top.about.compare.title')}
          </Text>
          {/*
            気になった動画と自分の動画を並べてコマ送りで見比べることができます。{`\n`}
            {`\n`}
            0.02秒、0.1秒ごとに細かく比較し、動きの違いを確認して理想のスタイルを手に入れましょう！
          */}
          <Text style={[
            styles.functionDescription,
            {fontSize: isWide? '1.3em' : '1em'},
          ]}>
            {t('top.about.compare.discription')}
          </Text>
        </View>


        <View style={[
          {width: isWide? windowWidth/3.5 : windowWidth},
          {paddingHorizontal: isWide? 50 : 25},
        ]}>
          <Image
            defaultSource={t('img.search')}
            resizeMode={'contain'}
            style={[
              styles.functionImg,
              {height: isWide? windowHeight/2 : windowHeight/1.5},
            ]}
          />
          {/* トリック検索 */}
          <Text style={[
            styles.functionTitle,
            {fontSize: isTxtSmall? '1.1em' : '1.3em'},
          ]}>
            {t('top.about.search.title')}
          </Text>
          {/*
            練習中のトリック・気になるトリックを検索することができます。{"\n"}
            あなただけの理想のスタイルを見つけていきましょう！
          */}
          <Text style={[
            styles.functionDescription,
            {fontSize: isWide? '1.3em' : '1em'},
          ]}>
            {t('top.about.search.discription')}
          </Text>
        </View>


        <View style={[
          {width: isWide? windowWidth/3.5 : windowWidth},
          {paddingHorizontal: isWide? 50 : 25},
        ]}>
          <Image
            defaultSource={t('img.good_cover')}
            resizeMode={'contain'}
            style={[
              styles.functionImg,
              {height: isWide? windowHeight/2 : windowHeight/1.5},
            ]}
          />
          {/* トリックごとの評価 */}
          <Text style={[
            styles.functionTitle,
            {fontSize: isTxtSmall? '1.1em' : '1.3em'},
          ]}>
            {t('top.about.rate.title')}
          </Text>
          {/*
            投稿ではなく、トリックごとに評価することができます。{"\n"}
            あなたのトリックの評価をもらってよりスタイリッシュにしていくだけでなく、他スケーターのトリックを評価して繋がることができます。
          */}
          <Text style={[
            styles.functionDescription,
            {fontSize: isWide? '1.3em' : '1em'},
          ]}>
            {t('top.about.rate.discription')}
          </Text>
        </View>


        <View style={[
          {width: isWide? windowWidth/3.5 : windowWidth},
          {paddingHorizontal: isWide? 50 : 25},
        ]}>
          <Image
            defaultSource={t('img.profile')}
            resizeMode={'contain'}
            style={[
              styles.functionImg,
              {height: isWide? windowHeight/2 : windowHeight/1.5},
            ]}
          />
          {/* スケーターと繋がる */}
          <Text style={[
            styles.functionTitle,
            {fontSize: isTxtSmall? '1.1em' : '1.3em'},
          ]}>
            {t('top.about.connect.title')}
          </Text>
          {/*
            トリック等でスケーターを検索でき、コメント・メッセージを送ることができます。{"\n"}
            エリアを設定すると近くで滑っているスケーターが表示されやすくなります。{"\n"}
            {"\n"}
            海外のスケーターとコメントやメッセージで連絡する際は自動翻訳されるので、多くのスケーターと繋がることができます！
          */}
          <Text style={[
            styles.functionDescription,
            {fontSize: isWide? '1.3em' : '1em'},
          ]}>
            {t('top.about.connect.discription')}
          </Text>
        </View>


        <View style={[
          {width: isWide? windowWidth/3.5 : windowWidth},
          {paddingHorizontal: isWide? 50 : 25},
        ]}>
          <Image
            defaultSource={t('img.analytics')}
            resizeMode={'contain'}
            style={[
              styles.functionImg,
              {height: isWide? windowHeight/2 : windowHeight/1.5},
            ]}
          />
          <Text style={[
            styles.functionTitle,
            {fontSize: isTxtSmall? '1.1em' : '1.3em'},
          ]}>
            {/* AI分析 */}
            <Text style={[
              styles.premium,
              {marginRight: 10},
            ]}>Premium</Text>
            {t('top.about.analytics.title')}
          </Text>
          {/*
            AIが各部位の動きを認識して数値化されます。\n各部位の動きや部位ごとの距離の変化がグラフ化され、理想のスタイルとの違いを分析することができます。\n\nAIを活用してスケートボードの新しい世界を楽しみましょう！
          */}
          <Text style={[
            styles.functionDescription,
            {fontSize: isWide? '1.3em' : '1em'},
          ]}>
            {t('top.about.analytics.discription')}
          </Text>
        </View>


        <View style={[
          {width: isWide? windowWidth/3.5 : windowWidth},
          {paddingHorizontal: isWide? 50 : 25},
        ]}>
          <Image
            defaultSource={t('img.effect')}
            resizeMode={'contain'}
            style={[
              styles.functionImg,
              {height: isWide? windowHeight/2 : windowHeight/1.5},
            ]}
          />
          <Text style={[
            styles.functionTitle,
            {fontSize: isTxtSmall? '1.1em' : '1.3em'},
          ]}>
            {/* エフェクト加工 */}
            <Text style={styles.premium}>Premium</Text>
            {`\n`}
            {t('top.about.effect.title')}
          </Text>
          {/*
            スマートフォンで撮影した動画をフィッシュアイ(魚眼レンズ)風に加工することができます。{`\n`}
            フィッシュアイの度合いを３段階から選択することができ、デザイン・迫力など最適な形に加工することができます。
          */}
          <Text style={[
            styles.functionDescription,
            {fontSize: isWide? '1.3em' : '1em'},
          ]}>
            {t('top.about.effect.discription')}
          </Text>
        </View>


        <View style={[
          {width: isWide? windowWidth/3.5 : windowWidth},
          {paddingHorizontal: isWide? 50 : 25},
        ]}>
          <Image
            defaultSource={t('img.fisheye')}
            resizeMode={'contain'}
            style={[
              styles.functionImg,
              {height: isWide? windowHeight/2 : windowHeight/1.5},
            ]}
          />
          <Text style={[
            styles.functionTitle,
            {fontSize: isTxtSmall? '1.1em' : '1.3em'},
          ]}>
            {/* フィッシュアイ(魚眼レンズ)加工 */}
            <Text style={styles.premium}>Premium</Text>
            {`\n`}
            {t('top.about.fisheye.title')}
          </Text>
          {/*
            スマートフォンで撮影した動画をフィッシュアイ(魚眼レンズ)風に加工することができます。{`\n`}
            フィッシュアイの度合いを３段階から選択することができ、デザイン・迫力など最適な形に加工することができます。
          */}
          <Text style={[
            styles.functionDescription,
            {fontSize: isWide? '1.3em' : '1em'},
          ]}>
            {t('top.about.fisheye.discription')}
          </Text>
        </View>


        <View style={[
          {width: isWide? windowWidth/3.5 : windowWidth},
          {paddingHorizontal: isWide? 50 : 25},
        ]}>
          <Image
            defaultSource={t('img.download_cover')}
            resizeMode={'contain'}
            style={[
              styles.functionImg,
              {height: isWide? windowHeight/2 : windowHeight/1.5},
            ]}
          />
          {/* 投稿動画のダウンロード */}
          <Text style={[
            styles.functionTitle,
            {fontSize: isTxtSmall? '1.1em' : '1.3em'},
          ]}>
            <Text style={styles.premium}>Premium</Text>
            {`\n`}
            {t('top.about.download.title')}
          </Text>
          {/*
            投稿した動画をダウンロードすることができます。{`\n`}
            動画をダウンロードしてSNSサービスや記録用に活用することができます。{`\n`}
            フィッシュアイ(魚眼レンズ)風に加工したものは、加工された状態でダウンロードされます。
          */}
          <Text style={[
            styles.functionDescription,
            {fontSize: isWide? '1.3em' : '1em'},
          ]}>
            {t('top.about.download.discription')}
          </Text>
        </View>


      </ScrollView>

      {/* SPで左端でない場合だけ表示 */}
      {!isWide && !isMaxLeft &&
        <View style={styles.leftWrap}>
          <AiOutlineDoubleLeft
            size={'2.5em'}
            color={blackColor}
            style={styles.left}
            onClick={onPressLeft}
          />
        </View>
      }

      {/* SPで右端でない場合だけ表示 */}
      {!isWide && !isMaxRight &&
        <View style={styles.rightWrap}>
          {isMaxLeft&&
            <>
              {/* swipe */}
              <Text style={styles.scrollTxt}>{t('top.about.moveRight')}</Text>
            </>
          }
          <AiOutlineDoubleRight
            size={'2.5em'}
            color={blackColor}
            style={styles.right}
            onClick={onPressRight}
          />
        </View>
      }

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#E6E9EA',
    paddingTop: 50,
    marginBottom: 150,
  },

  title: {
    color: blackColor,
    fontWeight: 'bold',
    fontSize: '1.7em',
    alignSelf: 'center',
  },
  titleWide: {
    color: blackColor,
    fontWeight: 'bold',
    fontSize: '2.5em',
    alignSelf: 'center',
    marginTop: 30,
  },
  underline: {
    height: 2,
    backgroundColor: blackColor,
    marginTop: 2,
  },

  scrollContainer: {
    flex: 1,
    scrollbarWidth: 'thin',
    scrollbarColor: '#aaa #f5f5f5',
    paddingVertical: 20,
  },
  contentContainer: {
    flexDirection: 'row', // 横方向に並べる
  },

  functionWrap: {
    paddingHorizontal: 25,
  },
  functionTitle: {
    color: blackColor,
    fontWeight: 'bold',
    paddingVertical: 15,
    alignSelf: 'center',
  },
  functionImg: {
    width: '100%',
  },
  functionDescription: {
    color: blackColor,
    alignSelf: 'center',
    fontSize: '1.1rem',
    lineHeight: 23,
  },

  rightWrap: {
    position: 'absolute',
    top: '35%',
    right: 10,
    flexDirection: 'row',
  },
  leftWrap: {
    position: 'absolute',
    top: '35%',
    left: 10,
  },

  scrollTxt: {
    color: blackColor,
    fontWeight: 'bold',
    writingMode: 'vertical-rl', // 右から左へ垂直方向に表示
    textOrientation: 'mixed', // 文字方向を自動で決定する
    letterSpacing: '0.05em', // 文字の間隔を調整
    alignSelf: 'center',
    marginRight: -5,
  },

  premium: {
    alignSelf: 'flex-start',
    fontSize: '0.8em',
    fontWeight: 'normal',
    color: fontColorWhite,
    backgroundColor: blackColor,
    paddingHorizontal: 5,
    paddingVertical: 2,
    borderRadius: 5,
    marginBottom: 2,
  },
});

export default HpContentFunction;
