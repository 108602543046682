import React, { useContext, useEffect } from 'react'
import { StyleSheet, ImageBackground, Image, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';

import { hpContext } from '../../../../HPContext';

function HpPointHowto(props) {
  const { t } = useTranslation();
  const isWide = useContext(hpContext).isWide;

  const pointHeight = props.pointHeight;
  const titleSize = props.titleSize;
  const contentSize = props.contentSize;

  const windowDimensions = useContext(hpContext).windowDimensions;
  const windowWidth = windowDimensions.width;


  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
    },
    leftContainer: {
      flex: 1,
      height: pointHeight,
    },
    rightContainer: {
      flex: isWide? 1.5 : 1.2,
      height: pointHeight,
      clipPath: 'polygon(15% 0, 100% 0, 100% 100%, 0% 100%)',
    },
    wrap: {
      flex: 1,
      alignItems: 'flex-start',
      justifyContent: 'center',
      marginLeft: isWide? '40%' : '10%',
    },
    head: {
      width: isWide? windowWidth*0.1 : windowWidth*0.25,
      aspectRatio: 2, // 画像のアスペクト比縦横(1:2)を保持
    },
    title: {
      color: 'white',
      fontSize: titleSize,
      fontWeight: 'bold',
      marginTop: isWide? '2%':'4%',
      marginBottom: isWide? '1%':'4%',
    },
    content: {
      color: 'white',
      fontSize: contentSize,
      textAlign: isWide? 'auto':'flex-start',
    },

    overlay: {
      ...StyleSheet.absoluteFillObject,
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // 半透明の白色オーバーレイ
      blurRadius: 10, // ぼかしの強さ
    },
  });
  
  return (
    <View style={styles.container}>
      <View style={styles.leftContainer}>
        <View style={styles.wrap}>
          <Image
            defaultSource={'./assets/img/point/number/01.png'}
            style={styles.head}
          />
          {/* How to */}
          <Text style={styles.title}>{t('top.point.howto.title')}</Text>
          {/* 初メイクから理想のスタイルまで */}
          <Text style={styles.content}>{t('top.point.howto.description')}</Text>
        </View>
      </View>

      <ImageBackground
        source={{uri: '/assets/img/point/howto.jpg'}}
        style={[
          styles.rightContainer,
          {height: pointHeight},
        ]}
      >
        <View style={styles.overlay}></View>
      </ImageBackground>
    </View>
  );
};


export default HpPointHowto;